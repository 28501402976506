import { createSlice } from "@reduxjs/toolkit";
export const initialState ={};
export const configSlice = createSlice({
    name: 'ipConfig',
    initialState: initialState,
    reducers:{
        setDataUser:(state,action)=>{
            return action.payload
        }
    }    
})
export const {setDataUser} = configSlice.actions

export default configSlice.reducer