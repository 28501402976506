import { includes } from "lodash"

export const calculeTotalAndDiscount=(discount=0,total)=>{
    const discountx = 1 -(parseFloat(discount)/100)
    return parseFloat(total)*discountx
}
export const calculeTotalDiscount=(discount=0,total)=>{
    const discountx = (parseFloat(discount)/100)
    return parseFloat(total)*discountx
}

export const newButtonProps = (rol,state)=>{
    
    if(state === "aprobado2"){
        return true;
    }
    if(includes(['gerencia','super-admin'],rol) && state !== "rechazado"){
        return false;
    }
    const variables = {
      ["jefe-compras"]: {acceso: "creado"},
      ["jefe-ventas"]: {acceso: "aprobado"},
      ["gerencia"]: {acceso: "aprobado1"},    
      ["analista-compras"]: {acceso: "rechazado"},    
    };
  
  if(variables[rol]["acceso"] === state){
    return false
  }else{
    return true
  }
  
    
  }
export const newButtonAlmacen = (rol,state)=>{
  if(state === "anulado" || state === "pagado" || state === "finalizado" ){
    return true
  }
    if( includes(["super-admin",'gerencia'], rol)){
      return false;
    }
    if(state === "finalizado"){
        return true;
    }
    if(rol === "gerencia" && state !== "rechazado"){
        return false;
    }
    const variables = {      
      ["jefe-ventas"]: {acceso: "pendiente"},
      ["jefe-almacen"]: {acceso: "aprobado"},    
      ["almacenes"]: {acceso: "aprobado"},   
      ["ejecutivo-ventas"]: {acceso: "aprobado"},  
    };
  
  if(variables[rol]["acceso"] === state){
    return false
  }else{
    return true
  }
  
    
  }
export const agregarEspacioAntesMayusculas = (texto)=> {
    // Utiliza una expresión regular para encontrar cada letra mayúscula y agregar un espacio antes de ella
    const textoConEspacios = texto.replace(/([A-Z])/g, ' $1');
  
    // Retorna el texto con los espacios agregados
    return textoConEspacios;
  }