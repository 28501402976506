
import React from 'react'

const BackdropLoad = () => {
  return (
    <div class="spinnerContainer2" id="loader2" >
    <div class="spinner"></div>
    <div class="loader">
      <p>loading</p>
      <div class="words">
        <span class="word">Comexa</span>
        <span class="word">Lidita</span>
        <span class="word">MakroAbasto</span>
        <span class="word">Delvalle</span>
        
      </div>
    </div>
  </div>
  )
}

export default BackdropLoad