import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/auth/authSlice";
import showRedirectSlice from "./features/auth/showRedirectSlice";
import  cartSlice from "./features/cart/cart";
import  cartSlice2 from "./features/cart/cart2";
import cartCotSlice from "./features/cart/cartCot";
import  requireSlice  from "./features/cart/require";
import transferSlice from "./features/cart/transferCart";
import configSlice from "./features/config/config";
import eventButton from "./features/config/eventButton";
import loadingSlice  from "./features/config/load";
import evaluationdetail from "./features/evaluation/evaluationdetail";
import evaluationOrder from "./features/evaluation/evaluationOrder";
import  purchaseSlice  from "./features/purchase_order/purchaseOrder";


const store = configureStore({
    reducer:{
        isAuth: authSlice,
        purchase:purchaseSlice,
        loading: loadingSlice,
        userData: configSlice,
        cartSales: cartSlice,
        cartSales2: cartSlice2,
        cartCoti: cartCotSlice,
        evalItemsState: evaluationdetail,
        evalItemOrder:evaluationOrder,
        showRedirect:showRedirectSlice,
        transferCart: transferSlice,
        requireCart: requireSlice,
        eventButton: eventButton
    }
})
export default store;