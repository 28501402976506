
import { createSlice } from "@reduxjs/toolkit";
import { concat, filter, findIndex, findKey } from "lodash";
import { storage } from "../../../store/storage";


export const initialState ={};
const nameStorage = "purchase_orders";
export const purchaseSlice = createSlice({
    name: 'order',
    initialState: storage.get(nameStorage) ? storage.get(nameStorage):initialState,
    reducers:{
        addOrder:(state,action)=>{
            const result = concat(state,action.payload)
            storage.set(nameStorage,result)
            return result;
        },
        delOrder:(state,action)=>{
            const data = filter(state,(o)=>{
                return  o.product.id !== action.payload.product_id
             })
             storage.set(nameStorage,data)
             return data;
        },
        resetOrder:(state,action)=>{
            
        },
        upOrder:(state,action)=>{
            const allData = state;
            const index = findIndex(allData,(o=>{
                return o.product.id === action.payload.product.id
            }))
            let data = allData[index];
            data={
                ...data,
                [action.payload.name]:action.payload.value
            }
           allData[index] = data;
           storage.set(nameStorage,allData)
           return allData
        },
        delProviderProducts:(state,action)=>{            
            const providerData = filter(state,(o)=>{
                return  o.product.provider_id !== action.payload.provider_id
             })
             storage.set(nameStorage,providerData)
             return providerData;
        },
    }
})

export const {addOrder,delOrder,resetOrder,upOrder,delProviderProducts} = purchaseSlice.actions
export default purchaseSlice.reducer