import axios from "axios";

import { baseURL } from "../config/AppConfig";


 const APIpublic = axios.create(
    {
        baseURL: baseURL,
        withCredentials:true,
        //headers: {'X-Custom-Header': 'foobar'}
    }
)

export default APIpublic;