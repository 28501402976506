
import { createSlice } from "@reduxjs/toolkit";

export const initialState =false;

export const loadingSlice = createSlice({
    name: 'loading',
    initialState:initialState,
    reducers:{
        setLoadOn:(state,action)=>{
            return true
        },
        setLoadOff:(state,action)=>{
            return false
        },
    }
})

export const {setLoadOn,setLoadOff} = loadingSlice.actions
export default loadingSlice.reducer