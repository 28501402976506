import { baseURL } from "../config/AppConfig";
import API from "../axios/api"
import APIpublic from "../axios/apiPublic";

const urlBase = 'api/'

export const businessInfo = async ()=>{
    //const {data} = await API.get(urlBase+'business-info')
    const {data} = await API.get(urlBase+'user/1')
    return data
}
export const userInfo = async ()=>{
    //const {data} = await API.get(urlBase+'business-info')
    const {data} = await APIpublic.get(urlBase+'user')
    return data
}
export const businessUpdate = async (values)=>{
    const {data} = await API.post(urlBase+'update_info',values)
    return data;
}
export const uploadLogo = async (values)=>{
    const {data} = await API.post(urlBase+'upload_logo',values, {headers: {
        'content-type': 'multipart/form-data',
      }},)
    return data;
}