import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";

export const initialState ={};
export const evaluationOrder = createSlice({
    name: 'evalItems',
    initialState: initialState,
    reducers:{
        addEvaluationItemsState:(state,action)=>{
            return action.payload
        },
        upOrderItem:(state,action)=>{
           
            const allData = state;
            const index= findIndex(allData.detail,(o=>{
                return o.id === action.payload.id
            }))
 
            let data = allData.detail[index].evaluation_detail
            data ={
                ...data,                
                [action.payload.name]:action.payload.value
            }
            allData.detail[index].evaluation_detail=data;
        
           return allData
        },
        upObservation:(state,action)=>{
            return {
            ...state,
            [action.payload.name]: action.payload.value
           } 
        }
    }
})
export const {addEvaluationItemsState,upOrderItem,upObservation} = evaluationOrder.actions
export default evaluationOrder.reducer