
import { createSlice } from "@reduxjs/toolkit";

export const initialState =false;

export const eventButton = createSlice({
    name: 'actionButton',
    initialState:initialState,
    reducers:{
        setLoad:(state,action)=>{
            return !state
        },
    }
})

export const {setLoad} = eventButton.actions
export default eventButton.reducer