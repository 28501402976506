import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'isAuth',
    initialState: {
        auth: null
    },
    reducers:{
        setIsAuth:()=>{
            return {auth:true}
        },
        setNoAuth:()=>{
            return {auth:false}
        }
    }
})

export const {setIsAuth,setNoAuth} = authSlice.actions
export default authSlice.reducer