import { createSlice } from "@reduxjs/toolkit";
import { concat, filter, findKey } from "lodash";
import { storage } from "../../../store/storage";



const nameStorage = "my_cart_cot";
export const initialState= [];
export const cartCotSlice = createSlice({
    name:"myCartCot",
    initialState: storage.get(nameStorage) ? storage.get(nameStorage):initialState,
    reducers:{
        addCartCotiItem:(state,action)=>{
            const index= findKey(state,(o=>{
                return o.stock_id === action.payload.stock_id & o.sale_presentation === action.payload.sale_presentation
            }))
            if(index){
                let allData = state;
                let data = allData[index]
                data={
                    ...data,
                    qty: action.payload.qty + data.qty
                }
                allData[index]=data;
                storage.set(nameStorage,allData)
                return allData
            }else{
                const result = concat(state,action.payload)
                storage.set(nameStorage,result)
                return result;
            }
        },
        upCartCotiItem:(state,action)=>{
            let allData = state;
            const index= findKey(allData,(o=>{
                return o.stock_id === action.payload.stock_id & o.sale_presentation === action.payload.sale_presentation
            }))          
           
                let data = allData[index]
                data={
                    ...data,
                    qty: action.payload.qty,
                    price_a: action.payload.price_a,
                    price_b: action.payload.price_b,
                }
                allData[index]=data;
                storage.set(nameStorage,allData)
                return allData
          
        },
        upCartSuggest:(state,action)=>{
            let allData = state;
            const index= findKey(allData,(o=>{
                return o.stock_id === action.payload.stock_id & o.sale_presentation === action.payload.sale_presentation
            }))          
           
                let data = allData[index]
                data={
                    ...data,
                    suggest: action.payload.suggest
                }
                allData[index]=data;
                storage.set(nameStorage,allData)
                return allData
          
        },
        upCartBono:(state,action)=>{
            let allData = state;
            const index= findKey(allData,(o=>{
                return o.stock_id === action.payload.stock_id & o.sale_presentation === action.payload.sale_presentation
            }))          
           
                let data = allData[index]
                data={
                    ...data,
                    bono: action.payload.bono
                }
                allData[index]=data;
                storage.set(nameStorage,allData)
                return allData
          
        },
        upCartPrice:(state,action)=>{
            let allData = state;
            const index= findKey(allData,(o=>{
                return o.stock_id === action.payload.stock_id & o.sale_presentation === action.payload.sale_presentation
            }))          
           
                let data = allData[index]
                data={
                    ...data,
                    price_a: action.payload.price_a,
                    price_b: action.payload.price_b,
                }
                allData[index]=data;
                storage.set(nameStorage,allData)
                return allData
          
        },
        upCartPrices:(state,action)=>{
            let allData = state;
            const index= findKey(allData,(o=>{
                return o.stock_id === action.payload.stock_id & o.sale_presentation === action.payload.sale_presentation
            }))          
           
                let data = allData[index]
                data={
                    ...data,
                    [action.payload.name]: action.payload.val,
                }
                allData[index]=data;
                storage.set(nameStorage,allData)
                return allData
          
        },
        delCartCotiItem:(state,action)=>{
            const allData = filter(state,o=>{
                return o.stock_id !== action.payload.stock_id
            });
            storage.set(nameStorage,allData)
            return allData;
        },
        resetCart:(state,action)=>{
            storage.remove(nameStorage);
            return []
        }
    }
})
export const {addCartCotiItem,upCartCotiItem,delCartCotiItem,resetCart,upCartSuggest,upCartBono,
    upCartPrice,upCartPrices} = cartCotSlice.actions
export default cartCotSlice.reducer