export const storage ={
    get(key){
        
        const value = localStorage.getItem(key)
        if(!value){
            return [];
        }
        const data = JSON.parse(value);
        return data;
    },
    set(key,value){
        localStorage.setItem(key,JSON.stringify(value))
    },
    remove(key){
        localStorage.removeItem(key)
    },
    clear(){
        localStorage.clear();
    }
}