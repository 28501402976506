import { createSlice } from "@reduxjs/toolkit";
import { filter, findKey } from "lodash";
import { storage } from "../../../store/storage";
import { round } from "lodash";

const nameStorage = "my_cart";
export const initialState = [];
export const cartSlice = createSlice({
  name: "myCart",
  initialState: storage.get(nameStorage)
    ? storage.get(nameStorage)
    : initialState,
  reducers: {
    addCartItem: (state, action) => {
      const existingItem = state.find((item) => {
        return (
          item.stock_id === action.payload.stock_id &&
          item.sale_presentation === action.payload.sale_presentation
        );
      });

      if (existingItem) {
        const updatedState = state.map((item) => {
          if (
            item.stock_id === action.payload.stock_id &&
            item.sale_presentation === action.payload.sale_presentation
          ) {
            let discount = parseFloat(item.aditional_discount);
            if (parseFloat(item.carActive) > 0) {
              if (parseInt(item.card) === 1) {
                discount = parseFloat(item.aditional_discount);
              } else {
                discount = 0;
              }
            }
            const updatedDiscountA = parseFloat(
              selectPrice(
                item.prices,
                item.qty + action.payload.qty,
                item.aditional_discount
              ).discount_a
            );

            const updatedDiscountB = parseFloat(
              selectPrice(
                item.prices,
                item.qty + action.payload.qty,
                item.aditional_discount
              ).discount_b
            );

            return {
              ...item,
              qty: item.qty + action.payload.qty,
              discount_a: round(updatedDiscountA, 2),
              discount_b: round(updatedDiscountB, 2),
            };
          } else {
            return item;
          }
        });

        storage.set(nameStorage, updatedState);
        return updatedState;
      } else {
        const newItem = {
          ...action.payload,
          discount_a: parseFloat(
            selectPrice(
              action.payload.prices,
              action.payload.qty,
              action.payload.aditional_discount
            ).discount_a
          ),
          discount_b: parseFloat(
            selectPrice(
              action.payload.prices,
              action.payload.qty,
              action.payload.aditional_discount
            ).discount_b
          ),
        };

        const updatedState = [...state, newItem];

        storage.set(nameStorage, updatedState);
        return updatedState;
      }
    },

    upCartItem: (state, action) => {
      let allData = state;
      const index = findKey(allData, (o) => {
        return (
          (o.stock_id === action.payload.stock_id) &
          (o.sale_presentation === action.payload.sale_presentation)
        );
      });

      let data = allData[index];
      let discount = parseFloat(data.aditional_discount);
      if (parseFloat(data.carActive) > 0) {
        if (parseInt(data.card) === 1) {
          discount = parseFloat(data.aditional_discount);
        } else {
          discount = 0;
        }
      }
      const updatedDiscountA = parseFloat(
        selectPrice(data.prices, action.payload.qty, discount)?.discount_a || 0
      );

      const updatedDiscountB = parseFloat(
        selectPrice(data.prices, action.payload.qty, discount)?.discount_b || 0
      );
      data = {
        ...data,
        qty: action.payload.qty,
        discount_a: round(updatedDiscountA, 2),
        discount_b: round(updatedDiscountB, 2),
      };
      allData[index] = data;
      storage.set(nameStorage, allData);
      return allData;
    },
    upCartSuggest: (state, action) => {
      let allData = state;
      const index = findKey(allData, (o) => {
        return (
          (o.stock_id === action.payload.stock_id) &
          (o.sale_presentation === action.payload.sale_presentation)
        );
      });

      let data = allData[index];
      data = {
        ...data,
        suggest: action.payload.suggest,
      };
      allData[index] = data;
      storage.set(nameStorage, allData);
      return allData;
    },
    upCartBono: (state, action) => {
      let allData = state;
      const index = findKey(allData, (o) => {
        return (
          (o.stock_id === action.payload.stock_id) &
          (o.sale_presentation === action.payload.sale_presentation)
        );
      });

      let data = allData[index];
      data = {
        ...data,
        bono: action.payload.bono,
      };
      allData[index] = data;
      storage.set(nameStorage, allData);
      return allData;
    },
    upCartPrice: (state, action) => {
      let allData = state;
      const index = findKey(allData, (o) => {
        return (
          (o.stock_id === action.payload.stock_id) &
          (o.sale_presentation === action.payload.sale_presentation)
        );
      });

      let data = allData[index];
      let discount = parseFloat(data.aditional_discount);
      if (parseFloat(data.carActive) > 0) {
        if (parseInt(data.card) === 1) {
          discount = parseFloat(data.aditional_discount);
        } else {
          discount = 0;
        }
      }
      const updatedDiscountA = parseFloat(
        selectPrice(data.prices, action.payload.qty + data.qty, discount)
          .discount_a
      );

      const updatedDiscountB = parseFloat(
        selectPrice(data.prices, action.payload.qty + data.qty, discount)
          .discount_b
      );

      data = {
        ...data,
        price_v: action.payload.price_v,
        discount_a: round(updatedDiscountA, 2),
        discount_b: round(updatedDiscountB, 2),
      };

      allData[index] = data;
      storage.set(nameStorage, allData);
      return allData;
    },
    upCartPrices: (state, action) => {
      let allData = state;
      const index = findKey(allData, (o) => {
        return (
          (o.stock_id === action.payload.stock_id) &
          (o.sale_presentation === action.payload.sale_presentation)
        );
      });

      let data = allData[index];

      data = {
        ...data,
        [action.payload.name]: action.payload.val,
      };
      allData[index] = data;
      storage.set(nameStorage, allData);
      return allData;
    },
    delCartItem: (state, action) => {
      const allData = filter(state, (o) => {
        return o.stock_id !== action.payload.stock_id;
      });
      storage.set(nameStorage, allData);
      return allData;
    },
    resetCart: (state, action) => {
      storage.remove(nameStorage);
      return [];
    },
  },
});
const selectPrice = (prices, quantity, discount) => {
  if (parseFloat(quantity) === 0) {
    return null;
  }
  if (parseFloat(discount) > 0) {
    return { discount_a: discount, discount_b: discount };
  }
  for (let i = 0; i < prices.length; i++) {
    const { min_units, max_units } = prices[i];
    if (
      parseFloat(quantity) >= parseFloat(min_units) &&
      parseFloat(quantity) <= parseFloat(max_units)
    ) {
      return prices[i];
    } else if (
      parseFloat(quantity) > parseFloat(max_units) &&
      i < prices.length - 1
    ) {
      const nextMinUnits = prices[i + 1].min_units;
      if (parseFloat(quantity) < nextMinUnits) {
        return prices[i + 1];
      }
    }
  }
  return null;
};
export const {
  addCartItem,
  upCartItem,
  delCartItem,
  resetCart,
  upCartSuggest,
  upCartBono,
  upCartPrice,
  upCartPrices,
} = cartSlice.actions;
export default cartSlice.reducer;
