import React, { lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import Backdrop from "../components/Backdrop";
import { AuthGuard } from "../guards";
import { PublicRoutes } from "../models/routes";
import Monitor from "../pages/Sala/Monitor";

import { setIsAuth, setNoAuth } from "../redux/features/auth/authSlice";
import { setDataUser } from "../redux/features/config/config";
import { userInfo } from "../services/acount";
import { RoutesWithNotFound } from "../utilities";

const SalesV1 = lazy(() => import("../pages/Salev1/SalesV1"));

const Login = lazy(() => import("../pages/Login"));
const Error = lazy(() => import("../pages/Error"));

function RoutesApp() {
  const authState = useSelector((state) => state.isAuth.auth);

  const dispatch = useDispatch();
  userInfo()
    .then((res) => {
      dispatch(setIsAuth());
      dispatch(setDataUser(res));
    })
    .catch((er) => {
      dispatch(setNoAuth());
    }, authState);

  return (
    <Suspense fallback={<Backdrop />}>
      {authState !== null ? (
        <BrowserRouter>
          <RoutesWithNotFound>
            <Route path={PublicRoutes.LOGIN} element={<Login />} />
            <Route element={<AuthGuard isAuth={authState} />}>
              <Route path="/" element={<SalesV1 />} />
              <Route path="/home" element={<SalesV1 />} />
              <Route path="/salesv1" element={<SalesV1 />} />
              <Route path="/monitor" element={<Monitor />} />
            </Route>

            <Route path="/error-404" element={<Error />} />
          </RoutesWithNotFound>
        </BrowserRouter>
      ) : (
        <Backdrop />
      )}
    </Suspense>
  );
}

export default RoutesApp;
