import { createSlice } from "@reduxjs/toolkit";
import { storage } from "../../../store/storage";
import { concat, filter, findKey } from "lodash";

const nameStorage = "require_cart";
export const initialState = [];
export const requireSlice = createSlice({
  name: "requireCart",
  initialState: storage.get(nameStorage)
    ? storage.get(nameStorage)
    : initialState,
  reducers: {
    addItemReq: (state, action) => {
        console.log(action.payload)
      const index = findKey(state, (o) => {
        return (
          (o.price_id === action.payload.price_id) &
          (o.item_barcode === action.payload.item_barcode)
        );
      });
      if (index) {
        let allData = state;
        let data = allData[index];
        data = {
          ...data,
          qty: action.payload.qty + data.qty,
        };
        allData[index] = data;
        storage.set(nameStorage, allData);
        return allData;
      } else {
        const result = concat(state, action.payload);
        storage.set(nameStorage, result);
        return result;
      }
    },
    upItem: (state, action) => {
      let allData = state;
      const index = findKey(allData, (o) => {
        return (
          (o.price_id === action.payload.price_id) &
          (o.item_barcode === action.payload.item_barcode)
        );
      });
      
      let data = allData[index];
      data = {
        ...data,
        qty: action.payload.qty,
      };
      allData[index] = data;
      storage.set(nameStorage, allData);
      return allData;
    },
    delItem: (state, action) => {
      const allData = filter(state, (o) => {
        return o.price_id !== action.payload.price_id;
      });
      storage.set(nameStorage, allData);
      return allData;
    },
    resetCart: (state, action) => {
      storage.remove(nameStorage);
      return [];
    },
  },
});
export const { addItemReq, upItem, delItem, resetCart } = requireSlice.actions;
export default requireSlice.reducer;
