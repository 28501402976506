import { createSlice } from "@reduxjs/toolkit";
import { redirect } from "react-router-dom";

export const showRedirectSlice = createSlice({
    name: 'showRedirectSlice',
    initialState: {
        redirect: false
    },
    reducers:{
        setRedirect:()=>{
            console.log("as")
            return {redirect: !redirect}
        },
    }
})

export const {setRedirect} = showRedirectSlice.actions
export default showRedirectSlice.reducer