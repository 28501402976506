export const PublicRoutes = {
    LOGIN: 'login'
}

export const PrivateRoutes ={
    PRIVATE:'app',
    HOME:'Home',
    CLIENTAREA:'ClientArea',
    PROVIDERS:'Providers',
    PRODUCTS:'Products',
    PREKARDEX:'PreKardex',
    KARDEX:'Kardex',
    REQUEST:'Request',
    REQUESTDETAIL:'RequestDetail',
    SALES:'Sales',
    SALESREPORT:'SalesReport',
}