import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { PublicRoutes } from '../models/routes'

const AuthGuard = ({isAuth,privateValidation}) => {
  return isAuth ? (
    <Outlet/>
  ):(
    <Navigate replace to={PublicRoutes.LOGIN}/>
  )
}

export default AuthGuard