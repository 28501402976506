import axios from "axios";
import { createContext } from "react";
import { baseURL } from "../config/AppConfig";
import { toast } from "react-toastify";


let pendingRequests = 0;
const loader = document.getElementById('loader');

const toastConfig=()=>{
    return{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    }
}
 const API = axios.create(
    {
        baseURL: baseURL,
        withCredentials:true,
        //headers: {'X-Custom-Header': 'foobar'}
    }
)
API.interceptors.request.use(data=>{
    return data;
},error=>{
    pendingRequests--;
  if (pendingRequests === 0) {
    loader.style.display = 'none';
  }
  return Promise.reject(error);
})
API.interceptors.response.use(
    
    (response) => {
        pendingRequests--;
  if (pendingRequests === 0) {
    loader.style.display = 'none';
  }
        const data = response;
      //  console.log('response:', response);
        if (response.status === 200) {
            return data;
        }
        if (response.status === 204) {
            return data;
        }
        if (response.status === 201) {
            return data;
        }

        toast.error(`001 ${response.statusText}: ${response}`,toastConfig)
        if (response.status === 401) {
            window.location.href = '/login';
        }
        if (response.status === 419) {
            window.location.href = '/login';
        }

        return Promise.reject(new Error(response.statusText || 'Error'));
    },
    (error) => {
        pendingRequests--;
        if (pendingRequests === 0) {
          loader.style.display = 'none';
        }
        //console.log('err:', error, error.response); // for debug
        let msg = "0.02";
        if (error.response && error.response.status) {
            switch (error.response.status) { 
                case 401:
                    window.location.href = '/login';
                    break;
                case 403:
                    window.location.href = '/login';
                    break;                         
                case 419:
                    window.location.href = '/login';
                    break;                         
                case 404:
                    toast.error(error.response.data?.message || 'Error 404',toastConfig)
                  /*   notification.error({
                        message: `404`,
                        description: error.response.data?.message || 'Error',
                    }); */
                    break;
                case 406:
                    toast.error(error.response.data?.message || 'Error 406',toastConfig)
                    break;
                case 422:
                    
                    toast.error(error.response.data?.message || 'Error 422',toastConfig)
                    break;
                default:
                    toast.error(error.response.data?.message || 'Error',toastConfig)
                  /*   notification.error({
                        message: `default`,
                        description: error.response.data?.message || 'Error',
                    }); */

            }
        }

        // throw new Error(error);
        return Promise.reject(error);
    },
);
export const AxiosContext = createContext(
    new Proxy(API, {
        apply: () => {
            throw new Error('You must wrap your component in an AxiosProvider');
        },
        get: () => {
            throw new Error('You must wrap your component in an AxiosProvider');
        },
    }),
    );

    export default API;