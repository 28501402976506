import React, { useMemo } from "react";
import ReactDOM from "react-dom/client";
import RoutesApp from "./routes/RoutesApp";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { createTheme, ThemeProvider } from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import 'react-toastify/dist/ReactToastify.css';
import API, { AxiosContext } from "./axios/api";
import { ToastContainer } from "react-toastify";
import { ProSidebarProvider } from "react-pro-sidebar";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#0b2948",
      contrastText: "#fff",
      dark: "#233082",
    },
    secondary: {
      main: "#D6A319",
      contrastText: "#fff",
    },
    progress:{
      main: "#f242f5"
    }
  },
  typography: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
 //AxiosInterceptors();
 const AxiosProvider =({children})=>{
  const axiosValue = useMemo(()=>{
    return API;
  },[])
  return (<AxiosContext.Provider value={axiosValue}>{children}</AxiosContext.Provider>)
 }
root.render(  
      <AxiosProvider>
    <Provider store={store}>
      <ProSidebarProvider>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <ToastContainer
          />
          <RoutesApp />
        </HelmetProvider>
      </ThemeProvider>
      </ProSidebarProvider>
    </Provider>  
      </AxiosProvider>
);
