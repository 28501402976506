import { Paper } from "@mui/material";
import { size } from "lodash";
import { useEffect, useRef, useState } from "react"
import { fNumber } from "../../utils/formatNumber";
import { calculeTotalAndDiscount } from "../../utils/operations";

const Monitor = () => {
    const [data,setData] = useState(null)
    const [client,setClient] = useState(null)
    const messagesRef = useRef(null);
    useEffect(()=>{
        const handleStorageChange = (e) => {
            if (e.key === 'my_cart') {
              setData(JSON.parse(e.newValue));              
            }
          };
          window.addEventListener('storage', handleStorageChange);
      
          return () => {
            window.removeEventListener('storage', handleStorageChange);
          };
    },[])
    useEffect(()=>{
        const handleStorageChange = (e) => {
            if (e.key === 'client') {
              setClient(JSON.parse(e.newValue));              
            }
          };
          window.addEventListener('storage', handleStorageChange);
      
          return () => {
            window.removeEventListener('storage', handleStorageChange);
          };
    },[])
    const updateData = (newData) => {
        localStorage.setItem('data', JSON.stringify(newData));
        setData(newData);
      };
      useEffect(() => {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      });
  return (
    <div className="p-3">
        <Paper >
            <div className="p-3 overflow-auto" style={{ maxHeight: "50vh" }}  ref={messagesRef}>
                <table className="table-print">
                    <thead>
                      <tr>
                      <th>Detalle</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                        <th>Des</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                        {
                            size(data)>0 && 
                            data.map((row,key)=>
                            <tr key={key}>
                                <td>{row.item}</td>
                                <td>{row.qty}</td>
                                <td>{fNumber(row.price_v)}</td>
                                <td>{fNumber(row.discount_a)}</td>
                                <td>{fNumber((parseFloat(row.price_v)-row.discount_a)* parseInt(row.qty))}</td>
                            </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
           {
           size(client) > 0 &&
           <div className="p-3" style={{ height: "45vh" }}>
            <div className="grid grid-cols-3 bg-white border-2 p-6 rounded-md mt-3">
      
          <div className="flex flex-col items-center">
            <p className="text-lg font-semibold">SUBTOTAL Bs:</p>
            <p className="text-4xl font-semibold text-gray-500">
              {fNumber(client.total)}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-lg font-semibold">DESCUENTO %:</p>
            <p className="text-4xl font-semibold text-yellow-500">
              {fNumber(client.discount)}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-lg font-semibold">TOTAL Bs:</p>
            <p className="text-4xl font-semibold text-green-500">
            {fNumber(calculeTotalAndDiscount(client.discount, client.total))}
            </p>
          </div>
       
        </div>
          <div className="grid grid-cols-4 bg-white border-2 p-4 rounded-md mt-3">
            <p className="text-gray-700 text-center text-2xl font-bold">{client.identification_number}</p>
            <p className="col-span-3 text-gray-700 text-2xl font-bold text-center uppercase">{client.business_name}</p>
          </div>
            </div>}
        </Paper>
    </div>
  )
}
export default Monitor