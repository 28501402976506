import { createSlice } from "@reduxjs/toolkit"
import { storage } from "../../../store/storage"
import { concat, filter, findKey } from "lodash";

const nameStorage = "tranfer_cart"
export const initialState = []
export const transferSlice = createSlice({
    name:"transferCart",
    initialState: storage.get(nameStorage) ? storage.get(nameStorage):initialState,
    reducers:{
        addItem:(state,action)=>{
            const index= findKey(state,(o=>{
                return o.stock_id === action.payload.stock_id & o.sale_presentation === action.payload.sale_presentation
            }))
            if(index){
                let allData = state;
                let data = allData[index]
                data={
                    ...data,
                    qty: action.payload.qty + data.qty
                }
                allData[index]=data;
                storage.set(nameStorage,allData)
                return allData
            }else{
                const result = concat(state,action.payload)
                storage.set(nameStorage,result)
                return result;
            }
        },
        upItem:(state,action)=>{
            let allData = state;
            const index= findKey(allData,(o=>{
                return o.stock_id === action.payload.stock_id & o.sale_presentation === action.payload.sale_presentation
            }))          
           
                let data = allData[index]
                data={
                    ...data,
                    qty: action.payload.qty,
                }
                allData[index]=data;
                storage.set(nameStorage,allData)
                return allData
        },
        delItem:(state,action)=>{
            const allData = filter(state,o=>{
                return o.stock_id !== action.payload.stock_id
            });
            storage.set(nameStorage,allData)
            return allData;
        },
        resetCart:(state,action)=>{
            storage.remove(nameStorage);
            return []
        }
    }
})
export const {addItem,upItem,delItem,resetCart} = transferSlice.actions
export default transferSlice.reducer